<template>
  <div class="accept-order">
    <template v-if="findingOrder">
      <baskyt-progress />
    </template>
    <template
      v-if="
        !findingOrder &&
          (orders != null || orders != undefined || orders.length > 0)
      "
    >
      <template v-for="order in orders">
        <div class="accept-order__wrapper m-auto" :key="order.updatedAt">
          <div class="accept-order__order-info">
            <div class="accept-order__details">
              <div class="accept-order__detail-item">
                <span class="accept-order__number"
                  >Order Id: {{ order.id }}</span
                >
              </div>
              <div class="accept-order__detail-item">
                <span>Customer</span>
                <span>{{ order.customerName }}</span>
              </div>
              <div class="accept-order__detail-item">
                <span>Total Bill</span>
                <span v-if="regionCheckUSorOther">$ {{ order.total }}</span>
                <span v-else>PKR {{ order.total }}</span>
              </div>
              <div
                v-if="regionCheckUSorOther === false"
                class="accept-order__detail-item"
              >
                <span>Delivery Time</span>
                <span>{{ parseInt(order.timeToDeliver) }} minutes</span>
              </div>
            </div>
            <div class="accept-order__note">
              <div class="accept-order__note__title">Order Notes:</div>
              <div class="accept-order__note__text">
                {{ order.note }}
              </div>
            </div>
          </div>
          <div class="accept-order__rider">
            <template v-if="order !== null">
              <div class="accept-order__rider__heading">
                Rider Information & Status
              </div>

              <order-status-single
                :order="order"
                :rider="order.rider"
                :key="order.__v"
              />
            </template>

            <template v-if="order.viewProducts">
              <div class="row pt-2">
                <div class="col-12 text-center ">
                  <button class="baskyt-btn" @click="viewProducts">
                    View Products
                  </button>
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>
<script>
import { mapState } from "vuex";
import OrderStatusSingle from "@/view/sub-pages/order-status/OrderStatusSingle";

export default {
  components: {
    "order-status-single": OrderStatusSingle
  },
  name: `${process.env.VUE_APP_COMPONENTS_PREFIX}accept-order`,
  data() {
    return {
      orders: [],
      findingOrder: true
    };
  },
  mounted() {
    this.findOrder();
  },
  methods: {
    findOrder() {
      const regionChecker = this.regionCheckUSorOther;
      const order =
        this.$store.getters.getPendingOrder(this.$route.params.id) ||
        this.$store.getters.getActiveOrder(this.$route.params.id) ||
        this.$store.getters.getDeliveredOrder(this.$route.params.id) ||
        this.$store.getters.getDeliveredSquadOrder(this.$route.params.id) ||
        this.$store.getters.getPendingSquadOrder(this.$route.params.id) ||
        this.$store.getters.getActiveSquadOrder(this.$route.params.id);

      if (order.inviteId) {
        const temp = [];
        order.deliveryRoute.forEach(drr => {
          temp.push({
            _id: null,
            stores: null,
            customerName: drr.customers.map(x => x.customer.name).join(" & "),
            avgRating: 2,
            status: drr.status,
            statusText: "On his way",
            viewProducts: order.state !== "Delivered",
            timeToDeliver: drr.timeDeliverEstimate / 60,
            time: drr.timeDeliverEstimate,
            total: order.orderAmount,
            id: order.inviteId,
            img: "/static-assets/defaults/user.jpg",
            rider: drr.rider,
            placedBy: "customers"
          });
        });
        this.orders = temp;
      } else if (!order.inviteId) {
        if (regionChecker) {
          this.orders = [
            {
              id: order.orderId,
              customerName: order.customer.name,
              status: order.status,
              viewProducts: order.status !== "Delivered",
              time: order.orderDeliverTime,
              timeToDeliver: order.orderDeliverTime,
              total: order.orderAmount,
              note: order.orderNote,
              rider: order.riderId,
              placedBy: "customers",
              // eslint-disable-next-line no-underscore-dangle
              updatedAt: order.updatedAt
            }
          ];
        } else {
          this.orders = [
            {
              id: order.orderId,
              customerName: order.receiver.name,
              status: order.types.status,
              time: order.time.timeToNext,
              timeToDeliver: order.time.timeToDeliverNumber / 60000,
              viewProducts: order.status !== "Delivered",
              total: order.amount.total,
              note: order.note,
              placedBy: order.placedBy,
              // eslint-disable-next-line no-underscore-dangle
              rider: order.rider,
              // eslint-disable-next-line no-underscore-dangle
              updatedAt: order.updatedAt
            }
          ];
        }
      }
      setTimeout(() => {
        this.findingOrder = false;
      }, 1000);
    },
    viewProducts() {
      this.$router.push({
        path: `/order/${this.orders[0].id}/products`
      });
    }
  },
  computed: {
    ...mapState({
      fetching: state =>
        state.orders.fetchingDelivered ||
        state.orders.fetchingActive ||
        state.orders.fetchingPending,
      regionCheckUSorOther: state => state.regions.region
    })
  },
  watch: {
    fetching() {
      this.findOrder();
    }
  }
};
</script>
<style lang="scss" scoped></style>
